@use "~@havenengineering/module-shared-owners-ui/dist/styles/variables.scss" as *; @use "~@havenengineering/module-shared-library/dist/style/_colors.scss" as *;
.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .header {
    color: #007cc2;
    font-weight: $fw-bolder;
    font-size: 26px;
  }
  a {
    color: #007cc2;
    text-decoration: underline;
  }
  img {
    max-width: 300px;
    margin-bottom: 24px;
  }
}
